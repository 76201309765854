const dashboard = {
	pageTitle: "Dashboard",
	description: "Dashboard",
	section: "Dashboard",
	linkName: "Dashboard",
	route: "/dashboard",
};

const customers = [
	{
		pageTitle: "Clienti",
		description: "Lista clienti",
		section: "Clienti",
		linkName: "Lista clienti",
		route: "/customers",
	},
	{
		pageTitle: "Aggiungi cliente",
		description: "Aggiungi un nuovo cliente",
		section: "Clienti",
		linkName: "Aggiungi cliente",
		route: "/customers/add",
	},
];

const contacts = {
	pageTitle: "Contatti",
	description: "",
	section: "Contatti",
	linkName: "Contatti",
	route: "/contatti",
	address: "Via Trieste 44, 26841, Casalpusterlengo (LO)",
	shortAddress: "Via Trieste 44, 26841",
	mail: "info@paolobassini.it",
	phone: "393 151 2786",
	instagram: "https://www.instagram.it",
	facebook: "",
	linkedin: "",
	twitter: "",
};

export { dashboard, customers, contacts };
