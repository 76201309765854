import React from "react";

// components
import Sidebar from "components/Sidebar";
import Header from "components/Header";
import Footer from "components/Footer";

import AddCustomer from "pages/Customers/AddCustomer";

const Customers = () => {
	return (
		<>
			<div className="wrapper">
				<Sidebar />
				<div className="page-wrapper">
					<Header />
					<div className="content-wrapper">
						<div className="content">
							<div className="card card-default">
								<div className="card-header align-items-center px-3 px-md-5">
									<h2>Clienti</h2>

									<button
										type="button"
										className="btn btn-primary"
										data-toggle="modal"
										data-target="#modal-add-contact">
										{" "}
										Aggiungi cliente
									</button>
								</div>

								<div className="card-body px-3 px-md-5">
									<div className="row">
										<div className="col-lg-6 col-xl-4">
											<div className="card card-default p-4">
												<a
													href="/"
													className="media text-secondary"
													data-toggle="modal"
													data-target="#modal-contact">
													<img
														src="images/user/u-xl-1.jpg"
														className="mr-3 img-fluid rounded"
														alt="Avatar"
													/>

													<div className="media-body">
														<h5 className="mt-0 mb-2 text-dark">Emma Smith</h5>
														<ul className="list-unstyled text-smoke text-smoke">
															<li className="d-flex">
																<i className="mdi mdi-map mr-1"></i>
																<span>Nulla vel metus 15/178</span>
															</li>
															<li className="d-flex">
																<i className="mdi mdi-email mr-1"></i>
																<span>exmaple@email.com</span>
															</li>
															<li className="d-flex">
																<i className="mdi mdi-phone mr-1"></i>
																<span>(123) 888 777 632</span>
															</li>
														</ul>
													</div>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div
								className="modal fade"
								id="modal-contact"
								tabindex="-1"
								role="dialog"
								aria-labelledby="exampleModalLabel"
								aria-hidden="true">
								<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
									<div className="modal-content">
										<div className="modal-header justify-content-end border-bottom-0">
											<button
												type="button"
												className="btn-edit-icon"
												data-dismiss="modal"
												aria-label="Close">
												<i className="mdi mdi-pencil"></i>
											</button>

											<div className="dropdown">
												<button
													className="btn-dots-icon"
													type="button"
													id="dropdownMenuButton"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false">
													<i className="mdi mdi-dots-vertical"></i>
												</button>

												<div
													className="dropdown-menu dropdown-menu-right"
													aria-labelledby="dropdownMenuButton">
													<a className="dropdown-item" href="/">
														Action
													</a>
													<a className="dropdown-item" href="/">
														Another action
													</a>
													<a className="dropdown-item" href="/">
														Something else here
													</a>
												</div>
											</div>

											<button
												type="button"
												className="btn-close-icon"
												data-dismiss="modal"
												aria-label="Close">
												<i className="mdi mdi-close"></i>
											</button>
										</div>

										<div className="modal-body pt-0">
											<div className="row no-gutters">
												<div className="col-md-6">
													<div className="profile-content-left px-4">
														<div className="card text-center px-0 border-0">
															<div className="card-img mx-auto">
																<img className="rounded-circle" src="images/user/u6.jpg" alt="User" />
															</div>

															<div className="card-body">
																<h4 className="py-2">Albrecht Straub</h4>
																<p>Albrecht.straub@gmail.com</p>
																<a className="btn btn-primary btn-pill btn-lg my-4" href="/">
																	Follow
																</a>
															</div>
														</div>

														<div className="d-flex justify-content-between ">
															<div className="text-center pb-4">
																<h6 className="pb-2">1503</h6>
																<p>Friends</p>
															</div>

															<div className="text-center pb-4">
																<h6 className="pb-2">2905</h6>
																<p>Followers</p>
															</div>

															<div className="text-center pb-4">
																<h6 className="pb-2">1200</h6>
																<p>Following</p>
															</div>
														</div>
													</div>
												</div>

												<div className="col-md-6">
													<div className="contact-info px-4">
														<h4 className="mb-1">Contact Details</h4>
														<p className="text-dark font-weight-medium pt-4 mb-2">Email address</p>
														<p>Albrecht.straub@gmail.com</p>
														<p className="text-dark font-weight-medium pt-4 mb-2">Phone Number</p>
														<p>+99 9539 2641 31</p>
														<p className="text-dark font-weight-medium pt-4 mb-2">Birthday</p>
														<p>Nov 15, 1990</p>
														<p className="text-dark font-weight-medium pt-4 mb-2">Event</p>
														<p>Lorem, ipsum dolor</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<AddCustomer />
						</div>
					</div>

					<Footer />
				</div>
			</div>
		</>
	);
};

export default Customers;
