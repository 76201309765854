import React from "react";

const Footer = () => {
	return (
		<React.Fragment>
			<footer className="footer mt-auto">
				<div className="copyright bg-white">
					<p>
						&copy; {new Date().getFullYear()} Copyright Paolo Bassini by{" "}
						<a className="text-primary" href="http://www.paolobassini.it/">
							Paolo Bassini
						</a>
						.
					</p>
				</div>
			</footer>
		</React.Fragment>
	);
};

export default Footer;
