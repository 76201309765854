import Dashboard from "pages/Dashboard";
import Customers from "pages/Customers";
import AddCustomer from "pages/Customers/AddCustomer";

import { dashboard, customers, contacts } from "site-structure.js";

const appname = process.env.REACT_APP_WEBSITE_NAME;

const routes = [
	{
		key: "dashboard",
		...dashboard,
		component: <Dashboard title={`${dashboard.pageTitle} - ${appname}`} />,
	},
	{
		key: "customers",
		section: "Clienti",
		linkName: "Clienti",
		pageTitle: "Clienti",
		route: "/customers",
		collapse: [
			{
				key: "customers-list",
				...customers[0],
				component: <Customers title={`${customers[0].pageTitle} - ${appname}`} />,
			},
			{
				key: "customers-add",
				...customers[1],
				component: <AddCustomer title={`${customers[1].pageTitle} - ${appname}`} />,
			},
		],
		collapseType: "standard",
	},
];

export { routes };
