import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
	GoogleAuthProvider,
	getAuth,
	signInWithPopup,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	signOut,
} from "firebase/auth";
import {
	getFirestore,
	query,
	getDocs,
	collection,
	where,
	addDoc,
} from "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyCmfmpM0q7nrlmdNxB3y0WD1HfAgkV39tY",
	authDomain: "paolobassini-1edba.firebaseapp.com",
	projectId: "paolobassini-1edba",
	storageBucket: "paolobassini-1edba.appspot.com",
	messagingSenderId: "1076170942112",
	appId: "1:1076170942112:web:e5e72bf7872f676fd38bc5",
	measurementId: "G-KKF0WML2T9",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

const addLocalUserIfDoesntMatch = async (uid, email, authProvider) => {
	const userMatch = await userExists(uid);

	console.log(userMatch);

	//se l'utente non è stato trovato e se non ritorna un errore dalla query lo aggiungo
	if (userMatch && userMatch.uid === undefined) {
		//creo nuovo oggetto utente (guest di default)
		let newUser = {
			uid,
			email,
			authProvider,
		};

		const responseNewUser = await fetch(
			`${process.env.REACT_APP_API_URL}/users/add/`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(newUser),
			}
		).catch((error) => {
			window.alert(error);
			return;
		});
	}
};

const signInWithGoogle = async () => {
	try {
		const res = await signInWithPopup(auth, googleProvider);
		const user = res.user;

		if (user.uid != null && user.uid != undefined)
			addLocalUserIfDoesntMatch(
				user.uid,
				user.email,
				user.providerData[0].providerId
			);
	} catch (err) {
		console.error(err);

		alert(err.message);
	}
};

const logInWithEmailAndPassword = async (email, password) => {
	try {
		const res = await signInWithEmailAndPassword(auth, email, password);
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};

const registerWithEmailAndPassword = async (name, email, password) => {
	try {
		const res = await createUserWithEmailAndPassword(auth, email, password);
		const user = res.user;

		if (user.uid != null && user.uid != undefined)
			addLocalUserIfDoesntMatch(
				user.uid,
				user.email,
				user.providerData[0].providerId
			);
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};

const sendPasswordReset = async (email) => {
	try {
		await sendPasswordResetEmail(auth, email);
		alert("Password reset link sent!");
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};
const logout = () => {
	signOut(auth);
};

const userExists = async (userId) => {
	//verifica che l'utente non sia ancora stato inserito nella collection 'Users' e restituisce ciò trova
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}/users/get-one/${userId}`
	);

	if (!response.ok) {
		//gestione errore
		return;
	}

	const record = await response.json();
	return record;
};

export {
	auth,
	signInWithGoogle,
	logInWithEmailAndPassword,
	registerWithEmailAndPassword,
	sendPasswordReset,
	logout,
	userExists,
};
