import React from "react";
import { Link, useLocation } from "react-router-dom";

import logo from "assets/images/logo.png";

import { routes } from "routes.js";

let pathname;

const getRoutes = (routes) =>
	routes.map((route) => {
		if ("route" in route) {
			// se è una route con figli
			return "collapse" in route ? (
				<>
					<li className="has-sub" key={route.key}>
						<a
							className="sidenav-item-link"
							href="/"
							data-toggle="collapse"
							data-target={"#" + route.key}
							aria-expanded="true"
							aria-controls={route.key}>
							{/* <i className="mdi mdi-email"></i> */}
							<span className="nav-text">{route.section}</span> <b className="caret"></b>
						</a>
						<ul className="collapse" id={route.key} data-parent="#sidebar-menu">
							<div className="sub-menu">{getRoutes(route.collapse)}</div>
						</ul>
					</li>
				</>
			) : (
				<>
					<li
						key={route.key}
						className={pathname.pathname === route.route ? "active" : ""}>
						<Link className="sidenav-item-link" to={route.route}>
							{/* <i className="mdi mdi-wechat"></i> */}
							<span className="nav-text">{route.linkName}</span>
						</Link>
					</li>
				</>
			);
		}
	});

const Sidebar = () => {
	pathname = useLocation();

	return (
		<React.Fragment>
			<aside className="left-sidebar sidebar-dark" id="left-sidebar">
				<div id="sidebar" className="sidebar sidebar-with-footer">
					<div className="app-brand">
						<a href="/index.html">
							<img src={logo} alt="Mono" />
							<span className="brand-name">PB</span>
						</a>
					</div>

					<div className="sidebar-left" data-simplebar style={{ height: "100%" }}>
						<ul className="nav sidebar-inner" id="sidebar-menu">
							{getRoutes(routes)}
						</ul>
					</div>

					<div className="sidebar-footer">
						<div className="sidebar-footer-content">
							<ul className="d-flex">
								<li>
									<a
										href="user-account-settings.html"
										data-toggle="tooltip"
										title="Profile settings">
										<i className="mdi mdi-settings"></i>
									</a>
								</li>
								<li>
									<a href="/" data-toggle="tooltip" title="No chat messages">
										<i className="mdi mdi-chat-processing"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</aside>
		</React.Fragment>
	);
};

export default Sidebar;
