const AddCustomer = () => {
	return (
		<>
			<div
				className="modal fade"
				id="modal-add-contact"
				tabindex="-1"
				role="dialog"
				aria-labelledby="exampleModalCenterTitle"
				aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
					<div className="modal-content">
						<form>
							<div className="modal-header px-4">
								<h5 className="modal-title" id="exampleModalCenterTitle">
									Crea nuovo cliente
								</h5>
							</div>
							<div className="modal-body px-4">
								<div className="form-group row mb-6">
									<label for="coverImage" className="col-sm-4 col-lg-2 col-form-label">
										Logo
									</label>
									<div className="col-sm-8 col-lg-10">
										<div className="custom-file mb-1">
											<input type="file" className="custom-file-input" id="coverImage" required />
											<label className="custom-file-label" htmlFor="coverImage">
												Scegli file...
											</label>
											<div className="invalid-feedback">Errore</div>
										</div>
									</div>
								</div>

								<div className="row mb-2">
									<div className="col-lg-6">
										<div className="form-group">
											<label for="firstName">Ragione sociale</label>
											<input
												type="text"
												className="form-control"
												id="ragione-sociale"
												placeholder="Inserisci la ragione sociale"
												value=""
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div className="form-group">
											<label for="lastName">Indirizzo</label>
											<input
												type="text"
												className="form-control"
												id="indirizzo"
												placeholder="Inserisci l'indirizzo"
												value=""
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div className="form-group mb-4">
											<label for="userName">Referente</label>
											<input
												type="text"
												className="form-control"
												id="referente"
												placeholder="Inserisci il referente"
												value=""
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div className="form-group mb-4">
											<label for="email">Email</label>
											<input
												type="email"
												className="form-control"
												id="email"
												placeholder="Inserisci l'email"
												value=""
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer px-4">
								<button type="button" className="btn btn-smoke btn-pill" data-dismiss="modal">
									Annulla
								</button>
								<button type="button" className="btn btn-primary btn-pill">
									Salva
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddCustomer;
